var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "prospecting" },
    [
      _c("div", { staticClass: "prospecting-top-wrapper" }, [
        _c(
          "div",
          { staticClass: "prospecting-top" },
          [
            _c("TextInput", {
              staticClass: "prospecting-top-search",
              attrs: {
                "left-icon": "search",
                placeholder: "Find prospects",
                "button-icon": _vm.query ? "close" : "",
                "submit-with-button": false
              },
              on: {
                submit: _vm.searchByQuery,
                "button-click": function() {
                  return _vm.searchByQuery("")
                }
              },
              model: {
                value: _vm.query,
                callback: function($$v) {
                  _vm.query = $$v
                },
                expression: "query"
              }
            }),
            _vm.filters.length
              ? _c(
                  "div",
                  { staticClass: "prospecting-top-filters" },
                  [
                    _vm.filters.length
                      ? _c("SimilarCompany", {
                          attrs: { organisation: _vm.similarCompany },
                          on: { submit: _vm.searchSimilarCompany }
                        })
                      : _vm._e(),
                    _vm.filters.length
                      ? _c("div", {
                          staticClass: "prospecting-top-filters-divider"
                        })
                      : _vm._e(),
                    _vm._l(_vm.availableFilters, function(filter) {
                      return _c("FilterDropdown", {
                        key: filter.id,
                        attrs: {
                          filter: filter,
                          selected: _vm.activeFilters[filter.id] || [],
                          "empty-text": "Unknown"
                        },
                        on: {
                          submit: function(selection) {
                            return _vm.selectFilter(filter.id, selection)
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  filter.id === "ambassadors"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "prospecting-top-filters-item"
                                        },
                                        [
                                          item.key
                                            ? _c("Avatar", {
                                                attrs: {
                                                  user: item.key,
                                                  "fallback-icon": "user"
                                                }
                                              })
                                            : _vm._e(),
                                          _vm._v(" " + _vm._s(item.value) + " ")
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    })
                  ],
                  2
                )
              : _vm.consumptionLoading
              ? _c(
                  "div",
                  { staticClass: "prospecting-top-filters" },
                  [
                    _c("b-skeleton", {
                      attrs: { height: "2.5rem", width: "10rem" }
                    }),
                    _c("div", {
                      staticClass: "prospecting-top-filters-divider"
                    }),
                    _vm._l(3, function(idx) {
                      return _c("b-skeleton", {
                        key: "prospecting-filter-loading-" + idx,
                        attrs: { height: "2.5rem", width: "10rem" }
                      })
                    })
                  ],
                  2
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "prospecting-top-btns" },
          [
            _vm.canWriteIntegrations
              ? _c("Button", {
                  attrs: { text: "Manage ambassadors" },
                  on: {
                    click: function() {
                      return (_vm.showAmbassadorsEdit = true)
                    }
                  }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          ref: "prospectstablewrapper",
          staticClass: "prospecting-table",
          style: _vm.maxHeightStyle,
          on: { wheel: _vm.handleScroll }
        },
        [
          _c("Table", {
            attrs: {
              items: _vm.prospects,
              headers: _vm.headers,
              sortable: false
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function(ref) {
                  var header = ref.header
                  var item = ref.item
                  return [
                    header.id === "name"
                      ? _c(
                          "div",
                          {
                            staticClass: "prospecting-table-item-name-wrapper"
                          },
                          [
                            _c("Avatar", {
                              attrs: {
                                user: { avatar: item.icon, username: item.name }
                              }
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "prospecting-table-item-name-content"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "prospecting-table-item-name-content-top"
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(header.key(item)) + " "
                                    ),
                                    item.linkedin_url
                                      ? _c("img", {
                                          staticClass:
                                            "prospecting-table-item-name-content-top-linkedin",
                                          attrs: {
                                            src: require("@/assets/logo/linkedin.svg"),
                                            alt: ""
                                          },
                                          on: {
                                            click: function() {
                                              return _vm.gotoLinkedIn(item)
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                ),
                                item.domain
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "prospecting-table-item-name-content-domain",
                                        on: {
                                          click: function() {
                                            return _vm.gotoDomain(item)
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(item.domain))]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ],
                          1
                        )
                      : header.id === "domain"
                      ? _c(
                          "p",
                          {
                            staticClass: "prospecting-table-item-domain",
                            on: {
                              click: function() {
                                return _vm.gotoDomain(item)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(header.key(item)) + " ")]
                        )
                      : header.id === "linkedin"
                      ? _c("img", {
                          staticClass: "prospecting-table-item-linkedin",
                          attrs: {
                            src: require("@/assets/logo/linkedin.svg"),
                            alt: ""
                          },
                          on: {
                            click: function() {
                              return _vm.gotoLinkedIn(item)
                            }
                          }
                        })
                      : header.id === "warm_intro"
                      ? _c("WarmIntroductions", {
                          attrs: {
                            profiles: item.profiles,
                            "pitch-loading": _vm.gotoPitchLoading === item.uuid
                          },
                          on: {
                            pitch: function(people) {
                              return _vm.gotoPitch(item, people)
                            }
                          }
                        })
                      : header.id === "actions"
                      ? _c(
                          "div",
                          { staticClass: "prospecting-table-item-actions" },
                          [
                            _c("Button", {
                              attrs: {
                                text: "Pitch",
                                type: "white",
                                loading: _vm.gotoPitchLoading === item.uuid,
                                disabled:
                                  !!_vm.gotoPitchLoading &&
                                  _vm.gotoPitchLoading !== item.uuid
                              },
                              on: {
                                click: function() {
                                  return _vm.gotoPitch(item)
                                }
                              }
                            }),
                            _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  position: "is-left",
                                  label: "Find similar companies",
                                  type: "is-dark"
                                }
                              },
                              [
                                _c("Button", {
                                  attrs: { icon: "search-star", type: "light" },
                                  on: {
                                    click: function() {
                                      return _vm.searchSimilarCompany(item)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm.consumptionLoading
            ? _c(
                "div",
                { staticClass: "prospecting-loading" },
                [
                  _c("b-loading", {
                    attrs: { active: "", "is-full-page": false }
                  })
                ],
                1
              )
            : _vm._e(),
          !_vm.consumptionLoading && !_vm.prospects.length
            ? _c(
                "div",
                {
                  staticClass: "prospecting-status",
                  class: { errored: _vm.loadingError }
                },
                [
                  _c("img", {
                    staticClass: "prospecting-status-icon",
                    attrs: {
                      src: require("@/assets/icons/" +
                        (_vm.loadingError ? "check-warning" : "search") +
                        ".svg"),
                      alt: ""
                    }
                  }),
                  _c("p", { staticClass: "prospecting-status-text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.loadingError
                            ? "Something went wrong while loading prospects, please try again later or contact support"
                            : "No prospects found. Try adjusting your filters or search query."
                        ) +
                        " "
                    )
                  ]),
                  _vm.loadingError
                    ? _c("Button", {
                        attrs: {
                          text: "Retry",
                          icon: "refresh",
                          type: "white",
                          "icon-left": true
                        },
                        on: { click: _vm.resetPaginatorConsumer }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("AmbassadorsEdit", {
        attrs: { visible: _vm.showAmbassadorsEdit },
        on: {
          close: function() {
            return (_vm.showAmbassadorsEdit = false)
          }
        }
      }),
      !!_vm.meetingCreateAccount
        ? _c("EditMeeting", {
            attrs: {
              visible: !!_vm.meetingCreateAccount,
              "force-account": _vm.meetingCreateAccount,
              "force-people": _vm.meetingCreatePeople
            },
            on: {
              created: _vm.handleMeetingCreated,
              close: _vm.closeMeetingCreate
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }