import { Paginator, getClient } from '../../../store/src/axios'

const client = getClient('/workspaces')

export async function getProspectsPaginator({
  workspace_id,
  query,
  organisation_id,
  industries,
  headquarters,
  employee_counts,
  is_account,
  ambassadors,
  page_size,
  callback = () => {}
}) {
  const body = {
    ...(query ? { query } : {}),
    ...(organisation_id ? { organisation_id } : {}),
    ...(industries ? { industries } : {}),
    ...(headquarters ? { headquarters } : {}),
    ...(employee_counts ? { employee_counts } : {}),
    ...(is_account ? { is_account } : {}),
    ...(ambassadors ? { ambassadors } : {})
  }
  const queryParam = {
    ...(page_size ? { page_size } : {})
  }
  const res = await client.post(`/${workspace_id}/prospects/search/`, body, {
    params: queryParam
  })
  callback(res)
  return new Paginator(
    client,
    res,
    () => {},
    undefined,
    (url) => client.post(url, body)
  )
}
